import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'

// markup
const UsedVacheronConstantinOverseasWatches = () => {
  const data = useStaticQuery(
    graphql`
      query queryUsedVacheronConstantinOverseasWatches {
        products: allStrapiProduct(
          filter: { brand: { eq: "Vacheron Constantin" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Vacheron Constantin Overseas Watches for Sale'}
      canonical={'/fine-watches/other-watches-brands/vacheron-constantin/'}
      description={
        'Gray & Sons are experts in buying and selling second hand Vacheron Constantin Overseas Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
              <StaticImage
                src="../../images/preowned/Vacheron-overseas-watchbanner.jpg"
                alt="Pre-Owned Certified Used Vacheron Constantin Watches Header"
                aria-label="Used Vacheron Constantin Watches Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div>
            <h1 className="text-3xl">
              Buy Sell Trade Consign Repair Restore Vacheron Constantin Overseas Watches
            </h1>
            <h2 className="p-5 lg:px-0 w-full flex justify-center">
              History of Vacheron Constantin Over Seas Watches
            </h2>
            <p>
              {' '}
              History of Vacheron Constantin Overseas Watches In an era where Swiss-made mechanical
              timepieces were being neglected and, cheap battery-powered watches were sweeping
              wrists around the globe, some of the most significant watch manufacturing companies
              decided it was time to introduce a new style to the market. The 1970s solidified the
              title of ‘holy trinity of timepieces’ that is the{' '}
              <a href="/fine-watches/patek-philippe/"> Patek Philippe,</a>{' '}
              <a href="/fine-watches/audemars-piguet/"> Audemars Piguet,</a> and{' '}
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Vacheron Constantin{' '}
              </a>{' '}
              trio. While hardly keeping up with the battle against overproduction, the three brands
              decided mid-quartz-crisis was the perfect time to come out with their now most popular
              collections; the Patek Philippe Nautilus, Audemars Piguet Royal Oak, and of course the
              Vacheron Constantin Overseas. With over two centuries in the luxury watchmaking
              industry, Vacheron Constantin made its leap into the luxury sports watch world in 1977
              with the Vacheron Constantin 222. Being the longest ongoing high-end Swiss watch
              manufacturer worldwide, VC named the 222 after the number of years they’ve been
              watchmaking. Unlike the classic Vacheron Constantin style that consumers were used to,
              designer Jorg Hysek decided on a more utilitarian design for the VC 222. The 222 has a
              thin barrel-shaped case and a round notched bezel, this design soon became the
              blueprint for the Vacheron Constantin Overseas collection. The Vacheron Constantin
              Overseas is a watch that exudes confidence. The details on this watch such as the
              inlaid Maltese cross at 5 o’clock or the hefty hexagonal link bracelet are only a few
              to distinguish the flair shown by this watch. Although the 222 had a short run, it was
              given a few different model designs. The Vacheron Constantin 222 was also made with a
              square-shaped case in various precious metals such as stainless steel, gold, and a
              two-tone mix of the two. VC enthusiasts were sad to see the discontinuation of the 222
              in the mid-80s, acquiring one was very difficult. However, after a decade, the
              Vacheron Constantin Phase 1 Overseas was introduced in 1996 and immediately loved.
              Combining elegance and robustness, the first Vacheron Constantin Overseas was crafted
              37mm in a tonneau-shaped watch that contained an ultra-flap automatic movement yet
              withstood water resistance to depths of 150 meters. VC differed from the design of the
              222 by adding a fluted bezel to the watch rather than the use of a traditional VC
              Maltese cross, however, this look allured consumers. Designers Vincent Kaufmann and
              Dino Modolo made it so that global travelers referenced this watch as one of their
              favorites. As the years progressed and we entered the twenty-first century, Vacheron
              Constantin released Phase 2 and Phase 3 of their Overseas collection. The Vacheron
              Constantin Overseas can now be acquired in a variety of 18 different references, each
              equipped with different styles and complications. Whether looking for a watch with a
              chronograph, perpetual calendar, world time, dual-time, or just time-date
              functionality, there is a Vacheron Constantin Overseas model for every kind of
              consumer.
            </p>

            <h2 className="text-3xl">
              Buying and Selling{' '}
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Pre-Owned Vacheron Constantin Overseas Watches
              </a>
            </h2>

            <h2>
              Since its release, the Vacheron Constantin Overseas has been a huge success. These
              elegant timepieces draw the attention of any watch enthusiast that comes near them.
              The Vacheron Constantin Overseas collection is one of Vacheron Constantin’s versatile
              pieces appropriate for almost any setting. Under the umbrella of Vacheron Constantin
              Overseas watches fall under the following references:
            </h2>

            <h2 className="text justify mx-auto text-2xl">
              Popular Vacheron Constantin Overseas watches and/or References:
            </h2>

            <li>
              {' '}
              &ensp;
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Pre-Owned Vacheron Constantin Overseas References: 2305v/100a-b170, 4500V/110A-B126,
                4500V/110A-B128{' '}
              </a>{' '}
            </li>

            <li>
              {' '}
              &ensp;
              <a href="/w522845-vacheron-constantin-overseas-43mm-491/">
                Pre-Owned Vacheron Constantin Overseas Chronograph References: 49150/000A-9745,
                49150/000R-9454, 5500V/110A-B481
              </a>{' '}
            </li>

            <li>
              {' '}
              &ensp;
              <a href="/w525546-vacheron-constantin-fiftsix-40mm-4600/">
                Pre-Owned Vacheron Constantin Overseas Dual Time References: 7910V/000T-B922,
                47450/000W-9511, 7900V/000R-B336
              </a>{' '}
            </li>

            <li>
              {' '}
              &ensp;
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Pre-Owned Vacheron Constantin Overseas World Time References: 7700V/110A-B176,
                7700V/110A-B172, 7700V-110A-B129
              </a>{' '}
            </li>

            <h2 className="text-2xl mx-auto">
              Gray &amp; Sons is the Best Source for Buying and Selling{' '}
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Pre-Owned Vacheron Constantin Watches{' '}
              </a>{' '}
            </h2>

            <p>
              Since 1980, Gray and Sons Jewelers has been catering to clients all around the country
              with all their luxury needs. All of the services we provide are done in our very own
              store, here, in the heart of Miami, Florida. We are the top watch store in all of
              South Florida specializing in buying pre-owned Vacheron Constantin Overseas watches,
              Selling used Vacheron Constantin Overseas watches, repairing second-hand Vacheron
              Constantin Overseas watches, and trading genuine Vacheron Constantin Overseas
              timepieces. Gray and Sons Jewelers is the #1 all-stop shop for all Vacheron Constantin
              Overseas watch needs. Our store is located in South Florida in the city of Surfside
              across the street from the famous Bal Harbour Shops. Aside from buying Vacheron
              Constantin Overseas Watches and selling genuine pre-owned Vacheron Constantin Overseas
              watches, Gray and Sons is also a certified repair center for all Swiss-made
              wristwatches. We have six master-trained watchmakers in-house with over 150 years of
              combined experience to repair Vacheron Constantin Overseas watches, including the
              reparation of a Vacheron Constantin Overseas. We take in repairs for swiss-made
              watches daily! Those looking to “get cash for my used Vacheron Constantin Overseas
              watch” or “sell my pre-owned Vacheron Constantin Overseas watch today” should visit
              www.grayandsons.com or www.sellusyourjewelry.com to request a free watch quote to sell
              or trade-in for one of ours! We have a brick-and-mortar store located in the heart of
              Miami, Fl near cities like Sunny Isles, Haulover, Aventura, Hollywood, Fort
              Lauderdale, Miami Beach, Coconut Grove, and Coral Gables, Key Biscayne, and many more.
              On our website, we have LIVE representatives Viktoria and Rich who are specialists in
              buying and selling Vacheron Constantin Overseas watches. Our LIVE CHAT feature allows
              clients all around the nation to make all inquiries about Vacheron Constantin Overseas
              watches and possibly work out a deal in real-time. Gray and Sons Jewelers is the best
              and most reliable place to buy used pre-owned Vacheron Constantin Overseas watches.
              Our master-trained watchmakers assure every Vacheron Constantin Overseas watch in our
              inventory is certified pre-owned and serviced ready for wear with a 1-year warranty
              covering the movement.
            </p>
          </div>
        </section>
      </div>
      <div className="w-full flex mx-auto justify-center pb-24 pt-24">
        <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
          <button>Shop Vacheron Constantin Overseas Watches</button>{' '}
        </a>
      </div>

      <section>
        <h2 className="text-center pb-48 ">IN-STOCK Vacheron Constantin Watches:</h2>

        <iframe
          className="content-center"
          src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d7178.937737903439!2d-80.1235056!3d25.8869528!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x88d9b2992b408533%3A0x8d702d6011cda310!2sGray%20and%20Sons%20Jewelers%7C%20Luxury%20Estate%20Watches%20%26%20Jewelry%209595%20Harding%20Ave%20Surfside%2C%20FL%2033154!3m2!1d25.8869528!2d-80.1235056!5e0!3m2!1sen!2sus!4v1655907861510!5m2!1sen!2sus"
          width="1380"
          height="300"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <br />

        <ProductGrid products={data.products.nodes} />
      </section>

      <section></section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/F_VZZhwYmGY'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default UsedVacheronConstantinOverseasWatches
